import { Component } from "../../components/base/Component";
import { ComponentFactory } from "../../services/ComponentFactory";
import { ICfa } from "../../models/ICfa";
import { GiftCardService } from "./GiftCardService";
import { DataLayerService } from "../services/DataLayerService";

export class GiftCardForm extends Component {
	dxeBearerToken: string | undefined;
	form: HTMLFormElement;
	focused: boolean;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		if (this._element.tagName !== "FORM") {
			throw new Error(`Non-form element passed in, element: ${this._element}`);
		}

		this.form = this._element as HTMLFormElement;
		this.focused = false;

		// tslint:disable-next-line
		this.initialize();
	}

	private async initialize(): Promise<void> {
		this.applyValidationRules(this.form);
		this.form.addEventListener("change", e =>
			this.handleCustomEmailOptionOneEffect()
		);
		this.form.addEventListener("change", e =>
			this.handleCustomEmailOptionTwoEffect()
		);
		this.form.addEventListener("submit", e => this.handleSubmission(e));

		// confirm that user upload is accepted file type
		this.handleUserUpload();

		// CFAC-34725 Fire whenever a user starts filling out a form.
		// This event is generally fired after user input in the first form field
		this._element
			.querySelector("#fullName")
			?.addEventListener("focus", e => this.handleUserInput(e));

		// CFAC-34725 Fire whenever a user is presented with a form on a page.
		DataLayerService.pushDataLayerEvent_Forms("form_view", {
			identifier: this.layerIdentifier,
			name: this.layerName,
			type: "GiftCardForm"
		});
	}

	private get layerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	private get layerName(): string {
		return this._element.dataset.layerName || "";
	}

	private handleUserUpload(): void {
		const uploadInput = this._element.querySelector("#image");
		const { acceptedFiles = "", customInputUploadMsg = "" } =
			this._element?.dataset;
		const acceptedFilesClean = acceptedFiles.split(",").map(i => i.trim());
		uploadInput?.addEventListener("change", e => {
			const ext = e.target?.value?.match(/\.([^\.]+)$/)[1];
			console.log(e);
			if (!acceptedFilesClean.includes(ext)) {
				const validator = $(this.form).validate();
				validator.showErrors({
					image: customInputUploadMsg
				});
				e.target?.value = "";
			} else {
				$(uploadInput).trigger("blur");
			}
		});
	}

	private handleCustomEmailOptionOneEffect(): void {
		const emailOptionOneDiv = this._element.querySelector(
			".email-option-one-div"
		);

		if (!emailOptionOneDiv) {
			console.warn(
				"No custom email div found. Check html and selector: .email-option-one-div"
			);
			return;
		}

		const transferOptionOneCheckbox =
			this._element.querySelector("input[value='1']");

		if (transferOptionOneCheckbox?.checked) {
			$(emailOptionOneDiv).slideDown();
		} else {
			$(emailOptionOneDiv).slideUp();
		}
	}

	private handleCustomEmailOptionTwoEffect(): void {
		const emailOptionTwoDiv = this._element.querySelector(
			".email-option-two-div"
		);

		if (!emailOptionTwoDiv) {
			console.warn(
				"No custom email div found. Check html and selector: .email-option-two-div"
			);
			return;
		}

		const transferOptionTwoCheckbox =
			this._element.querySelector("input[value='2']");

		if (transferOptionTwoCheckbox?.checked) {
			$(emailOptionTwoDiv).slideDown();
		} else {
			$(emailOptionTwoDiv).slideUp();
		}
	}

	private applyValidationRules(form: HTMLFormElement): void {
		const messages = this._element.dataset;
		const errorClass = "hasError";

		$.validator.addMethod(
			"equalToField",
			function (value, element, param) {
				var target = $(param);
				return this.optional(element) || value === target.val();
			},
			"Both fields must have the same value."
		);

		$(form).validate({
			errorClass: "hasError",
			errorPlacement: (error, element) => {
				error.appendTo(element.next(".error-container"));
			},
			highlight: element => {
				$(element).parent().addClass(errorClass);
			},
			messages: {
				fullName: messages.customNameMsg ?? "",
				email: messages.customEmailMsg ?? "",
				address: messages.customAddressMsg ?? "",
				reason: messages.customReplaceReasonMsg ?? "",
				acknowledge: messages.customAcknowledgeMsg ?? ""
			},
			rules: {
				fullName: {
					required: true
				},
				email: {
					email: true,
					required: true
				},
				address: {
					required: true
				},
				reason: {
					required: true
				},
				acknowledge: {
					required: true
				},
				emailOptionTwo: {
					email: true,
					required: function () {
						return $("#transferOptionTwo").is(":checked");
					}
				},
				emailOptionTwoReenter: {
					email: true,
					equalToField: "#emailOptionTwo",
					required: function () {
						return $("#transferOptionTwo").is(":checked");
					}
				},
				image: {
					required: true
				}
			},
			unhighlight: element => {
				$(element).parent().removeClass(errorClass);
			}
		});
	}

	// CFAC-34725 Fire whenever a user starts filling out a form.
	// This event is generally fired after user input in the first form field
	private async handleUserInput(e: Event): Promise<void> {
		if (!this.focused) {
			DataLayerService.pushDataLayerEvent_Forms("form_start", {
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "GiftCardForm"
			});

			this.focused = true;
		}
	}

	private async handleSubmission(e: Event): Promise<void> {
		e.preventDefault();
		this.toggleSubmitBtn(false);
		const formHasErrors = !this.validate();
		if (formHasErrors) {
			this.toggleSubmitBtn(true);

			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "GiftCardFormSubmission",
				error_message: "Form Has Errors",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "GiftCardForm"
			});

			return;
		}

		await this.submitForm();
	}

	private async submitForm(): Promise<void | null> {
		const {
			submissionEndpoint = "",
			formDatasourceId = "",
			redirectSuccess,
			redirectError
		} = this._element.dataset;

		let formData = new FormData(this.form);
		formData.append("formDatasourceId", formDatasourceId);
		const recaptchaVal = this.form["g-recaptcha-response"]?.value;

		if (!recaptchaVal) {
			console.warn(`No recaptcha value found in payload`);
			this.toggleFieldErr("recaptchaField", true);
			this.toggleSubmitBtn(true);

			return null;
		}

		const [result, err] = await GiftCardService.sendForm(
			submissionEndpoint,
			formData
		);

		if (err) {
			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "GiftCardFormSubmission",
				error_message: "Error in submitting form.",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "GiftCardForm"
			});

			console.warn("Error in submitting form.");
			window.location.href = redirectError;

			return;
		}

		if (result && result.success) {
			// Account for From Success submission response from BE:
			// CFAC-34725 Fire whenever a user successfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_complete", {
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "GiftCardForm"
			});

			window.location.href = redirectSuccess;
		} else if (result && result.error) {
			// Account for Form Validation Failed response from BE:
			Object.keys(result.errors).forEach(key => {
				console.warn(`${key}, ${result.errors[key]}`);
				this.toggleFieldErr(key, true);
			});
		} else {
			// Account for ERROR response from BE:
			console.warn("Error while submitting the form");

			// CFAC-34725 Fire whenever a user unsuccessfully completes a form
			DataLayerService.pushDataLayerEvent_Forms("form_error", {
				error_category: "GiftCardFormSubmission",
				error_message: "Error in submitting form - BE.",
				identifier: this.layerIdentifier,
				name: this.layerName,
				type: "GiftCardForm"
			});

			window.location.href = redirectError;
		}
	}

	private toggleFieldErr(fieldName: string, toggleOn: boolean): void {
		const field = this._element.querySelector(`[data-element='${fieldName}']`);

		if (!field) {
			console.warn(`No field ${fieldName} found. Check DOM.`);

			return;
		}

		toggleOn
			? field.classList.add("display")
			: field.classList.remove("display");
	}

	private validate(): boolean {
		if (this._element) {
			return $(this._element).valid();
		}

		return false;
	}

	private toggleSubmitBtn(toggleOn: boolean): void {
		const submitBtn = this._element.querySelector("button[type='submit']");
		const disabledClass = "disabled";

		if (!submitBtn) {
			throw new Error(`Submit button not found for ${submitBtn}`);
		}

		toggleOn
			? submitBtn.classList.remove(disabledClass)
			: submitBtn.classList.add(disabledClass);
	}
}

ComponentFactory.registerComponent("giftCardForm", GiftCardForm);
